<script>
import { isEqual, orderBy } from 'lodash'

import AppCard from '@/components/card/Card.vue'
import userService from '@/services/user/user'
import { toastMixins } from '@/mixins'

export default {
  mixins: [toastMixins],
  props: {
    groupLogonMasterProp: Object,
    isLoadingProp: Boolean
  },
  components: {
    AppCard
  },
  data() {
    return {
      isLoading: false,
      groupCode: null,
      allMembers: [],
      oldSelectedMembers: [],
      newSelectedMembers: [],
      errMsg: null
    }
  },
  computed: {
    saveButtonEnable() {
      const oldMembers = orderBy(
        this.oldSelectedMembers.map(el => el.userId),
        ['userId'],
        ['asc']
      )
      const newMembers = orderBy(
        this.newSelectedMembers.map(el => el.userId),
        ['userId'],
        ['asc']
      )
      return !isEqual(oldMembers, newMembers)
    }
  },
  watch: {
    groupLogonMasterProp() {
      this.initializeGroupMembers()
    }
  },
  async created() {
    this.groupCode = this.$route.params.groupCode
    await Promise.all([this.fetchAllMembers()])
    this.initializeGroupMembers()
  },
  methods: {
    async fetchAllMembers() {
      this.isLoading = true
      try {
        const res = await userService.getUserLogonMasters({})
        this.allMembers = res.data.data.map(({ userId, userName }) => ({
          userId,
          userName
        }))
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
      this.isLoading = false
    },
    initializeGroupMembers() {
      if (this.groupLogonMasterProp) {
        const { users } = this.groupLogonMasterProp
        this.oldSelectedMembers = users.map(({ userId, userName }) => ({
          userId,
          userName
        }))
        this.newSelectedMembers = users.map(({ userId, userName }) => ({
          userId,
          userName
        }))
        return
      }
    },
    buttonClicked(type, payload = {}) {
      switch (type) {
        case 'MEMBER':
          this.memberSelected(payload)
          break
        case 'SAVE':
          this.$emit(
            'updatedMember',
            this.newSelectedMembers.map(el => el.userId)
          )
          break
        case 'CANCEL':
          this.$router.push({
            name: 'Admin.GroupLogonMaster.Detail',
            params: { groupCode: this.groupCode }
          })
          break
        default:
          break
      }
    },
    memberSelected(member) {
      const { userId } = member
      const alreadySelected = this.newSelectedMembers.find(
        el => el.userId === userId
      )
      if (alreadySelected) {
        this.newSelectedMembers = this.newSelectedMembers.filter(
          el => el.userId !== userId
        )
        return
      }
      this.newSelectedMembers.push(member)
    }
  }
}
</script>

<template>
  <div>
    <app-card>
      <b-row>
        <!-- topic -->
        <b-col cols="12">
          <span class="font-weight-bold text-lg">
            ผู้ใช้งานในกลุ่ม
          </span>
        </b-col>

        <!-- content -->
        <b-col cols="12" class="mt-2">
          <b-row>
            <b-col
              cols="6"
              sm="4"
              md="3"
              xl="2"
              class="mt-2"
              v-for="member in allMembers"
              :key="member.userId"
            >
              <b-button
                :variant="
                  newSelectedMembers.find(el => el.userId === member.userId)
                    ? 'primary'
                    : 'outline-primary'
                "
                @click="buttonClicked('MEMBER', member)"
                class="w-100 h-100"
                size="sm"
                :disabled="isLoadingProp || isLoading"
              >
                <span>{{ member.userName }}</span>
                <br />
                <span class="text-xs">( {{ member.userId }} )</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <!-- hover line -->
        <b-col cols="12">
          <hr />
        </b-col>

        <!-- buttons -->
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button
              class="mr-3 px-5"
              variant="primary"
              @click="buttonClicked('SAVE')"
              :disabled="!saveButtonEnable || isLoadingProp || isLoading"
            >
              <b-spinner
                v-if="isLoadingProp || isLoading"
                variant="light"
                small
              ></b-spinner>
              <span v-else>บันทึก</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="px-5"
              @click="buttonClicked('CANCEL')"
              :disabled="isLoadingProp || isLoading"
            >
              ยกเลิก
            </b-button>
          </div>
        </b-col>
      </b-row>
    </app-card>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
