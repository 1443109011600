<script>
import InfoForm from './create-edit-form-components/InfoForm.vue'
import MemberForm from './create-edit-form-components/MemberForm.vue'
import PermissionForm from './create-edit-form-components/PermissionForm.vue'

export default {
  props: {
    modeProp: String, // CREATE , EDIT
    groupLogonMasterProp: Object,
    isLoadingProp: Boolean
  },
  components: {
    InfoForm,
    MemberForm,
    PermissionForm
  },
  methods: {
    createdInfoHandler(payload) {
      this.$emit('createdInfo', payload)
    },
    updatedInfoHandler(payload) {
      this.$emit('updatedInfo', payload)
    },
    updatedMemberHandler(payload) {
      this.$emit('updatedMember', payload)
    },
    updatedPermissionHandler(payload) {
      this.$emit('updatedPermission', payload)
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="12">
      <info-form
        :modeProp="modeProp"
        :groupLogonMasterProp="groupLogonMasterProp"
        :isLoadingProp="isLoadingProp"
        @createdInfo="createdInfoHandler"
        @updatedInfo="updatedInfoHandler"
      ></info-form>
    </b-col>
    <b-col cols="12" v-if="modeProp === 'EDIT'">
      <member-form
        class="mt-3"
        :groupLogonMasterProp="groupLogonMasterProp"
        :isLoadingProp="isLoadingProp"
        @updatedMember="updatedMemberHandler"
      ></member-form>
    </b-col>
    <b-col cols="12" v-if="modeProp === 'EDIT'">
      <permission-form
        class="mt-3"
        :groupLogonMasterProp="groupLogonMasterProp"
        :isLoadingProp="isLoadingProp"
        @updatedPermission="updatedPermissionHandler"
      ></permission-form>
    </b-col>
  </b-row>
</template>

<style></style>
