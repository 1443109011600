<script>
import { mapState } from 'vuex'

import { toastMixins } from '@/mixins'
import userService from '@/services/user/user'
import authService from '@/services/auth/auth'

import MenuBar from './components/MenuBar.vue'

import CreateEditForm from '../components/CreateEditForm.vue'

export default {
  mixins: [toastMixins],
  components: { MenuBar, CreateEditForm },
  data() {
    return {
      isLoading: false,
      groupCode: null,
      groupLogonMaster: null,
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: (state) => state.constants.defaultDelayMs
    })
  },
  created() {
    this.groupCode = this.$route.params.groupCode
    this.fetchGroupLogonMaster()
  },
  methods: {
    async fetchGroupLogonMaster() {
      this.isLoading = true
      try {
        const res = await authService.getGroupLogonMaster(this.groupCode)
        this.groupLogonMaster = res.data.data
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
      this.isLoading = false
    },
    async updatedInfoHandler(payload) {
      this.isLoading = true
      try {
        await authService.updateGroupLogonMaster(this.groupCode, payload)
        this.mxDisplaySuccessMessage('แก้ไขข้อมูลกลุ่มผู้ใช้งานสำเร็จ')
        this.isLoading = false
        setTimeout(() => {
          this.$router.push(
            `/admin/group-logon-masters/detail/${this.groupCode}`
          )
        }, this.defaultDelayMs * 0.5)
      } catch (err) {
        this.isLoading = false
        this.errMsg = err.response.data.thMessage
      }
    },
    async updatedMemberHandler(payload) {
      this.isLoading = true
      try {
        await authService.setUserIdsForGroupCode({
          groupCode: this.groupCode,
          allowUserIds: [...payload]
        })
        this.isLoading = false
        this.mxDisplaySuccessMessage('แก้ไขข้อมูลผู้ใช้งานภายในกลุ่มนี้สำเร็จ')
        setTimeout(() => {
          this.$router.push(
            `/admin/group-logon-masters/detail/${this.groupCode}`
          )
        }, this.defaultDelayMs * 0.5)
      } catch (err) {
        this.isLoading = false
        this.errMsg = err.response.data.thMessage
      }
    },
    async updatedPermissionHandler(payload) {
      this.isLoading = true
      try {
        await Promise.all(
          payload.map((el) => {
            const {
              systemMenuAccessId,
              allowPermissions,
              notAllowPermissions
            } = el
            return authService.updateSystemMenuAccess(systemMenuAccessId, {
              allowPermissions,
              notAllowPermissions
            })
          })
        )
        this.mxDisplaySuccessMessage(
          'แก้ไขข้อมูลการจำกัดสิทธิ์ของกลุ่มผู้ใช้งานสำเร็จ'
        )
        this.isLoading = false
        setTimeout(() => {
          this.$router.push(
            `/admin/group-logon-masters/detail/${this.groupCode}`
          )
        }, this.defaultDelayMs * 0.5)
      } catch (err) {
        this.errMsg = err.response.data.thMessage
        this.isLoading = false
      }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="แก้ไขรายละเอียดกลุ่มผู้ใช้งาน"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>

    <!-- edit form -->
    <create-edit-form
      modeProp="EDIT"
      :groupLogonMasterProp="groupLogonMaster"
      :isLoadingProp="isLoading"
      @updatedInfo="updatedInfoHandler"
      @updatedMember="updatedMemberHandler"
      @updatedPermission="updatedPermissionHandler"
    ></create-edit-form>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
