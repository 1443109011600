<script>
import _ from 'lodash'
import moment from 'moment'

import AppCard from '@/components/card/Card.vue'

export default {
  props: {
    modeProp: String,
    groupLogonMasterProp: Object,
    isLoadingProp: Boolean
  },
  components: { AppCard },
  data() {
    return {
      oldData: null,
      newData: null,
      labelMap: {
        groupCode: 'รหัสกลุ่มผู้ใช้งาน *',
        groupName: 'ชื่อกลุ่มผู้ใช้งาน *'
      },
      keysForCreate: ['groupCode', 'groupName'],
      keysForUpdate: ['groupName']
    }
  },
  computed: {
    saveButtonEnable() {
      if (this.modeProp === 'CREATE') {
        const focusData = _.pick(this.newData, this.keysForCreate)
        if (
          Object.values(focusData).some(
            el => _.isNil(el) || (_.isString(el) && el.trim().length === 0)
          )
        ) {
          return false
        }
        return true
      }
      if (this.modeProp === 'EDIT') {
        return !_.isEmpty(this.checkDataDifference())
      }
      return false
    }
  },
  watch: {
    groupLogonMasterProp() {
      this.initializeData()
    }
  },
  created() {
    this.initializeData()
  },
  methods: {
    initializeData() {
      switch (this.modeProp) {
        case 'CREATE':
          const defaultData = {}
          this.keysForCreate.forEach(key => {
            defaultData[key] = null
          })
          this.oldData = { ...defaultData }
          this.newData = { ...defaultData }
          break
        case 'EDIT':
          if (this.groupLogonMasterProp) {
            this.oldData = _.pick(this.groupLogonMasterProp, this.keysForUpdate)
            this.newData = { ...this.oldData }
          }
          break
        default:
          break
      }
    },
    checkDataDifference() {
      const payload = {}
      if (this.oldData && this.newData) {
        const focusKeys =
          this.modeProp === 'CREATE' ? this.keysForCreate : this.keysForUpdate
        focusKeys.forEach(key => {
          let oldValue = _.isString(this.oldData[key])
            ? this.oldData[key].trim()
            : null
          let newValue = _.isString(this.newData[key])
            ? this.newData[key].trim()
            : null

          if (_.isString(oldValue) && oldValue.trim().length === 0)
            oldValue = null
          if (_.isString(newValue) && newValue.trim().length === 0)
            newValue = null

          if (!_.isEqual(oldValue, newValue)) {
            payload[key] = _.isString(newValue) ? newValue.trim() : newValue
          }
        })
      }
      return payload
    },
    buttonClicked(type) {
      const payload = this.checkDataDifference()

      switch (type) {
        case 'SAVE':
          if (this.modeProp === 'CREATE') {
            this.emitEvent('CREATE', payload)
          } else if (this.modeProp === 'EDIT') {
            this.emitEvent('UPDATE', payload)
          }
          break
        case 'CANCEL':
          if (this.modeProp === 'CREATE') {
            this.$router.push({
              name: 'Admin.GroupLogonMasters'
            })
          }
          if (this.modeProp === 'EDIT') {
            this.$router.push({
              name: 'Admin.GroupLogonMaster.Detail',
              params: { groupCode: this.$route.params.groupCode }
            })
          }
          break
        default:
          break
      }
    },
    emitEvent(eventType, payload) {
      switch (eventType) {
        case 'CREATE':
          this.$emit('createdInfo', payload)
          break
        case 'UPDATE':
          this.$emit('updatedInfo', payload)
          break
      }
    }
  }
}
</script>

<template>
  <div>
    <app-card>
      <b-row>
        <!-- topic -->
        <b-col cols="12">
          <span class="font-weight-bold text-lg">
            ข้อมูลกลุ่มผู้ใช้งาน
          </span>
        </b-col>

        <!-- content -->
        <b-col cols="12" class="mt-2">
          <b-row
            class="mt-2"
            align-v="center"
            v-for="(value, key, index) in newData"
            :key="index"
          >
            <b-col cols="3">
              <span>{{ labelMap[key] }}</span>
            </b-col>
            <b-col cols="9">
              <b-form-input
                v-model="newData[key]"
                type="text"
                :disabled="isLoadingProp"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-col>

        <!-- hover line -->
        <b-col cols="12">
          <hr />
        </b-col>

        <!-- buttons -->
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button
              class="mr-3 px-5"
              variant="primary"
              @click="buttonClicked('SAVE')"
              :disabled="!saveButtonEnable || isLoadingProp"
            >
              <b-spinner v-if="isLoadingProp" variant="light" small></b-spinner>
              <span v-else>บันทึก</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="px-5"
              @click="buttonClicked('CANCEL')"
              :disabled="isLoadingProp"
            >
              ยกเลิก
            </b-button>
          </div>
        </b-col>
      </b-row>
    </app-card>
  </div>
</template>

<style></style>
